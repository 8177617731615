import dayjs from "dayjs"

export function formatDate(datetime, format = "YYYY-MM-DD HH:mm:ss", offset = 0, unit = 'hour') {
  return dayjs(datetime).add(offset, unit).format(format)
}

export function isSameDay(srcDay, targetDay) {
  return srcDay.isSame(targetDay, 'day')
}

export function dayAgo(srcDay, targetDay) {
  let dayDiff = srcDay.diff(targetDay, 'day')
  return dayDiff
  // if (dayDiff > 0) {
  //   return dayDiff
  // } else {
  //   return srcDay.diff(targetDay, 'hour')
  // }
}

export function listDates(startDate, endDate, convert=false) {
  const dates = []
  let currentDate = startDate
  const addDays = function (days) {
    const date = new Date(this.valueOf())
    date.setDate(date.getDate() + days)
    return date
  }

  if (!convert) {
    dates.push(currentDate)
  } else {
    dates.push(formatDate(currentDate, "YYYY-MM-DD"))
  }
  if (dayjs(startDate).isSame(endDate, 'day')) {
    return dates
  }
  while (currentDate < endDate) {
    currentDate = addDays.call(currentDate, 1)
    if (!convert) {
      dates.push(currentDate)
    } else {
      dates.push(formatDate(currentDate, "YYYY-MM-DD"))
    }
  }
  return dates
}