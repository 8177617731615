<template>
  <Toast />

  <DataTable :value="scrapData" :show-gridlines="true" responsiveLayout="scroll" :loading="loading">
    <template v-if="mode == 'single'">
      <Column field="period" :style="{ 'min-width': '80px', 'width': '80px' }">
        <template #body="{ data }">
          {{ data.period[0] }} - {{ data.period[1] }}
        </template>
      </Column>
      <!-- Loop scrappableProducts -->
      <Column v-for="product in scrappableProducts" :key="product.product_id" :field="product.product_name"
        :style="{ 'min-width': '110px', 'width': '120px' }">
        <template #header>
          <div class="flex flex-column align-items-center">
            <p>{{ product.product_name }}</p>
            <Button v-if="isSameDay(dayjs(), dayjs(startDate))" icon="pi pi-minus"
              class="p-button-rounded-sm p-button-purpply" @click="selectScrapProduct(product)"></Button>
          </div>
        </template>
        <template #body="{ data, field }">
          <template v-if="data['period'][0] == '總計'">
            <div class="flex justify-content-center"><span v-for="(d, idx) in data[field]" :key="idx">{{ d
            }}&nbsp;</span>
            </div>
          </template>
          <div v-else class="flex flex-column align-items-center">
            <template v-for="(d, idx) in data[field]" :key="idx">
              <!-- {{ idx }} : {{ d }} -->
              <div class="flex">
                <Button :label="`(${formatDate(d.scrap_datetime, 'HH:mm')} 報廢) ${d.quantity} ${d.uom}`"
                  class="p-1 p-button-text"></Button>
                <Button v-show="isSameDay(dayjs(), dayjs(startDate))" icon="pi pi-pencil" class="p-button-text"
                  @click="selectUpdateProduct(d)" />
              </div>
            </template>
          </div>
        </template>
      </Column>
    </template>
    <template v-else-if="mode == 'range'">
      <Column field="date" :style="{ 'min-width': '100px', 'width': '100px' }">
        <template #body="{ data }">
          {{ (data.date instanceof Date) ? formatDate(data.date, "YYYY-MM-DD") : data.date }}
        </template>
      </Column>
      <Column v-for="product in scrappableProducts" :key="product.product_id" :field="product.product_name"
        :style="{ 'min-width': '110px', 'width': '120px' }">
        <template #header>
          <div class="flex flex-column align-items-center">
            <p>{{ product.product_name }}</p>
          </div>
        </template>
        <template #body="{ data, field }">
          <!-- {{ data[field] }} -->
          <div class="flex justify-content-center align-items-center gap-1">
            <template v-for="d in data[field]" :key="d.id">
              <span>{{ d }}</span>
            </template>
          </div>
        </template>
      </Column>
    </template>
  </DataTable>

  <!-- 報廢 Dialog -->
  <Dialog header=" " v-model:visible="showScrapProducts" :dismissableMask="true" :closable="!scrappingProduct"
    :breakpoints="{ '960px': '96vw' }" :style="{ width: '60vw' }" :modal="true" @hide="cleanupProductList">
    <div class="my-4">
      <p>1. 請選擇報廢批次</p>
      <template v-for="lot in selectedScrapProduct.lots.slice().reverse()" :key="lot.id">
        <!-- {{ lot }} {{ getPropertiesType(lot) }} -->
        <div v-if="lot.quantity != 0 && getPropertiesType(lot) != 'inventory' && canBeScraped(lot)" class="field-checkbox">
          <RadioButton name="scrapProduct" :inputId="lot.id" :value="lot" v-model="scrapProduct" />
          <label :for="lot.id">{{ lot.quantity }} {{ lot.uom }} (日期: {{ formatDate(lot.create_datetime, format =
              "YYYY-MM-DD HH:mm:ss")
          }})</label>
          <p v-if="isSameDay(dayjs(), dayjs(lot.create_datetime))" class="font-semibold">
            &nbsp;[今日]</p>
        </div>
      </template>
      <p v-if="selectedScrapProduct.lots.length == 0">目前沒有可報廢的商品</p>

      <hr />
      <p>2.請填入報廢量</p>
      <div v-if="selectedScrapProduct.uom != 'c.c'">
        <div class="flex gap-2 align-items-center">
          <div class="flex">
            <InputNumber v-model="scrapProductQuantity" :max="scrapProductQuantityMax"
              :inputProps="{ 'type': 'tel' }" />
            <span class="p-inputgroup-addon">{{ selectedScrapProduct.uom }}</span>
          </div>
          <span>+</span>
          <div class="flex">
            <InputMask v-model="scrapProductQuantityLess" mask="9/9" placeholder="2/3" slotChar="n/m" type="tel" />
            <span class="p-inputgroup-addon">{{ selectedScrapProduct.uom }}</span>
          </div>
        </div>
      </div>
      <div v-else class="flex">
        <InputNumber v-model="scrapProductQuantity" min="0" :max="scrapProduct == null ? '0' : scrapProductQuantityMax"
          :inputProps="{ 'type': 'tel' }" />
        <span class="p-inputgroup-addon">{{ selectedScrapProduct.uom }}</span>
      </div>
      <p v-if="scrapProduct != null">報廢 {{ scrapProductAmount }} {{ scrapProduct.uom }} </p>

    </div>
    <template #footer>
      <Button class="p-button-secondary" label="報廢" :loading="scrappingProduct"
        :disabled="selectedScrapProduct.lots.length == 0 || scrapProduct == null || scrapProductAmount == 0 || scrapProductAmount > scrapProduct.quantity"
        @click="submitScrapList" />
    </template>
  </Dialog>

  <!-- 更新 Dialog -->
  <Dialog header="更新數量" v-model:visible="showUpdateProduct" :dismissableMask="true" :breakpoints="{ '960px': '96vw' }"
    :style="{ width: '70vw' }" :modal="true" @hide="cleanUp">
    <!-- {{ selectedUpdateProduct }} -->
    <p class="mt-4">當前紀錄: {{ selectedUpdateProduct.product_name }} {{ selectedUpdateProduct.quantity }} {{ selectedUpdateProduct.uom }}</p>
    <div class="my-2 flex">
      <InputNumber v-model="inputUpdateQunatity" :inputProps="{ 'type': 'tel' }" :maxFractionDigits="2" />
      <span class="p-inputgroup-addon">{{ selectedUpdateProduct.uom }}</span>
    </div>
    <template #footer>
      <Button class="p-button-secondary" label="更新" @click="submitUpdate" :disabled="inputUpdateQunatity == null" />
    </template>
  </Dialog>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import dayjs from 'dayjs'
import { useToast } from 'primevue/usetoast'
import { formatDate, isSameDay, dayAgo, listDates } from '@/composable/date'
import {
  getOverviewData,
  getScrappableProducts,
  postScrapProducts,
  postCancelScrap,
  getBoms,
} from '@/api'
import { deepClone } from '@/helpers'

const props = defineProps(['startDate', 'endDate', 'mode'])

const toast = useToast()

const loading = ref(false)
const defaultManuData = ref({})
const rangeDateList = ref([])
const bomData = ref({})
const showScrapProducts = ref(false)
const showInvalid = ref(false)
const showUpdateProduct = ref(false)
const moreThanOne = ref(false)
const lessThanOne = ref(false)
const selectedScrapProduct = ref({})
const selectedUpdateProduct = ref({})
const scrapOrders = ref([])  // 報廢紀錄顯示於茶葉報廢表格
const scrappableProducts = ref([])
const scrapProduct = ref(null)
const scrapProductQuantity = ref(null)
const scrapProductQuantityLess = ref("0/0")
const scrappingProduct = ref(false)
const inputUpdateQunatity = ref(null)

const scrapData = computed(() => {
  if (props.mode == 'range') {
    let totalG = { date: '總計' }
    let totalList = [..._.cloneDeep(rangeDateList.value), totalG]
    let diffDay = 0
    scrapOrders.value.forEach(inv => {
      // console.log('inv', inv)
      diffDay = dayAgo(dayjs(inv.scrap_datetime), dayjs(props.startDate))
      if (!Object.keys(totalList[diffDay]).includes(inv.product_name)) {
        totalList[diffDay][inv.product_name] = { sum: 0, uom: inv.uom }
      }
      if (!Object.keys(totalG).includes(inv.product_name)) {
        totalG[inv.product_name] = { sum: 0, uom: inv.uom }
      }
      totalList[diffDay][inv.product_name].sum += inv.quantity
      totalG[inv.product_name].sum += inv.quantity

      // console.log('totalList', totalList[diffDay])
    })

    Object.keys(totalG).forEach(key => {
      if (key !== 'date') {
        const sum = totalG[key].sum
        const [amount, uom] = calBomAmount(sum, key, true)
        totalG[key].sum = _.round(amount, 2)
        totalG[key].uom = uom
      }
    })

    // console.log('totalList', totalList)
    return totalList
  } else {
    let list = deepClone(defaultManuData.value)
    let total = { "period": ["總計", ""] }
    if (Array.isArray(list)) {
      list.push(total)
    }

    scrapOrders.value.forEach(inv => {
      let periodIdx = Math.floor((dayjs(inv.scrap_datetime).hour() - 8) / 2)
      // 依照[列]分類，Array [period 1, period 2 ...]
      if (periodIdx >= 0 && periodIdx < 8) {
        if (!Object.keys(list[periodIdx]).includes(inv.product_name)) {
          // console.log('scrapData periodIdx', list[periodIdx])
          list[periodIdx][inv.product_name] = [inv]
        } else {
          // console.log('scrapData periodIdx', list[periodIdx])
          list[periodIdx][inv.product_name].push(inv)
        }
      }

      // 總計數量
      if (inv.product_name in total) {
        total[inv.product_name][0] += inv.quantity
        // console.log('total', total[inv.product_name], inv)
        // total[inv.product_name].push(inv.uom)
      } else {
        total[inv.product_name] = [inv.quantity, inv.uom]
      }
    })
    return list
  }
})
const scrapProductAmount = computed(() => {
  let amount = 0
  if (scrapProductQuantity.value != null) {
    amount += scrapProductQuantity.value
  }
  if (scrapProductQuantityLess.value != "") {
    let bits = scrapProductQuantityLess.value.split("/")
    if (parseInt(bits[1], 10) == 0) {
      amount += 0
    } else {
      amount += (parseInt(bits[0], 10) / parseInt(bits[1], 10))
    }
  }
  if (Number.isInteger(amount)) {
    return amount
  } else {
    return amount.toFixed(2)
  }
})
const scrapProductQuantityMax = computed(() => {
  if (scrapProduct.value == null) {
    return 0
  } else {
    return Math.floor(scrapProduct.value.quantity)
  }
})

watch(() => props.startDate, (newVal, oldVal) => {
  fetchTableContents().then(() => { })
})
watch(() => props.endDate, (newVal, oldVal) => {
  fetchTableContents().then(() => { })
})
watch(() => props.mode, (newVal, oldVal) => {
  fetchTableContents()
})

const calBomAmount = (value, selectedToppingName, reverse=false) => {
  // console.log('calBomAmount', value, selectedToppingName)
  let bom = bomData.value.find((bom) => bom.product_name == selectedToppingName)
  let ratio = bom.out / bom.unit
  let amount = reverse ? value / ratio : value * ratio
  // console.log('calBomAmount bom:', bom, 'amount:', amount)
  if (Number.isInteger(amount)) {
    return [amount, bom.uom]
  } else {
    return [parseFloat(amount.toFixed(2)), bom.uom]
  }
}
const getDefaultData = async () => {
  defaultManuData.value = await fetch('data/defaultPeriods.json').then(res => res.json()).then(d => d)
}
const getScrapData = async () => {
  let resp = await getScrappableProducts(
    dayjs().startOf('day').format("YYYY-MM-DD HH:mm:ss"),
    dayjs().endOf('day').format("YYYY-MM-DD HH:mm:ss")
  )
  scrappableProducts.value = resp.data.products.filter((prod) => prod.category == '半成品').sort((a, b) => a.product_id - b.product_id)
}
const getBomData = async () => {
  let resp = await getBoms()
  bomData.value = resp.data.boms.map((bom) => {
    return {
      "product_id": bom.product_out_id,
      "product_name": bom.product_out_name,
      "unit": bom.product_consumed[0].quantity,
      "out": bom.product_out_quantity,
      "uom": bom.product_consumed[0].uom
    }
  })
  // console.log('getBomData', bomData)
}
const fetchTableContents = async () => {
  loading.value = true
  rangeDateList.value = listDates(props.startDate, props.endDate).map((date) => ({ date: date }))
  // console.log('rangeDateList.value', rangeDateList.value)
  await getScrapData()
  await getBomData()

  try {
    let resp
    if (props.mode == 'range') {
      resp = await getOverviewData(
        dayjs(props.startDate).startOf('day').format("YYYY-MM-DD HH:mm:ss"),
        dayjs(props.endDate).endOf('day').format("YYYY-MM-DD HH:mm:ss")
      )
    } else {
      resp = await getOverviewData(
        dayjs(props.startDate).startOf('day').format("YYYY-MM-DD HH:mm:ss"),
        dayjs(props.startDate).endOf('day').format("YYYY-MM-DD HH:mm:ss")
      )
    }

    scrapOrders.value = resp.data.scrap_orders
  } catch (error) {
    console.log('error', error)
  }
  loading.value = false
}
//
const submitScrapList = async () => {
  if (scrapProductQuantity.value == null) {
    showInvalid.value = true
  } else {
    scrappingProduct.value = true
    postScrapProducts([
      {
        product_id: scrapProduct.value.product_id,
        quantity: scrapProductAmount.value,
        lot_id: scrapProduct.value.id,
      }
    ]).then((resp) => {
      scrappingProduct.value = false
      showScrapProducts.value = false
      toast.add({ severity: 'success', summary: '成功', detail: '報廢成功', life: 2000 })
      setTimeout(async () => {
        await fetchTableContents()
      }, 1000)
    }).catch((error) => {
      scrappingProduct.value = false
      showScrapProducts.value = false
      toast.add({ severity: 'error', summary: '失敗', detail: '報廢失敗', life: 2000 })
    })
  }
}
// 更新
const submitUpdate = async () => {
  await postCancelScrap(selectedUpdateProduct.value.id)

  postScrapProducts([
    {
      product_id: selectedUpdateProduct.value.product_id,
      quantity: inputUpdateQunatity.value,
      lot_id: selectedUpdateProduct.value.lot_id,
    }
  ]).then((resp) => {
    showUpdateProduct.value = false
    toast.add({ severity: 'success', summary: '成功', detail: '更新成功', life: 2000 })
    setTimeout(async () => {
      await fetchTableContents()
    }, 3000)
  }).catch((error) => {
    showUpdateProduct.value = false
    toast.add({ severity: 'error', summary: '失敗', detail: '更新失敗', life: 2000 })
  })
}
const getPropertiesType = (obj, mapping = false) => {
  // 獲取 obj.properties["type"], eg. inventory from {"properties": "inventory"}
  try {
    // console.log('getPropertiesType', JSON.parse(obj.properties))
    if (mapping) return properyTypes.value[JSON.parse(obj.properties)["type"]]
    else return JSON.parse(obj.properties)["type"]
  } catch (error) {
    return ""
  }
}
const canBeScraped = (lot) => {
  const product_name = selectedScrapProduct.value.product_name
  const interval = ['粉圓', '草仔粿'].includes(product_name) ? 'day' : 'week'
  const minDatetime = dayjs().subtract(1, interval)
  return dayjs(lot.create_datetime).isAfter(minDatetime)
}
const selectScrapProduct = (prod) => {
  showScrapProducts.value = true
  selectedScrapProduct.value = { ...prod }
}
const selectUpdateProduct = (prod) => {
  showUpdateProduct.value = true
  selectedUpdateProduct.value = { ...prod }
}
const cleanupProductList = () => {
  inputUpdateQunatity.value = null
  scrapProduct.value = null
  scrapProductQuantity.value = 0
  scrapProductQuantityLess.value = "0/0"
}

getDefaultData()

onMounted(async () => {
  await fetchTableContents()
})
</script>

<style lang="scss" scoped>
.p-button-rounded-sm {
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
}
</style>
