import axios from 'axios'
import { sleep } from '@/helpers';

console.log('process.env.NODE_ENV', process.env.NODE_ENV, process.env.VUE_APP_BASE_URL)

const shopHash = () => localStorage.getItem('shop_id')

const request = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_URL}`
})

async function requestWithRetry(url, opts, retryTimes = 5, retryInterval = 1) {
  while (retryTimes) {
    try {
      return await request.request(url, opts)
    } catch (err) {
      retryTimes -= 1
      if (retryTimes) await sleep(retryInterval)
      else throw err
    }
  }
}

export async function getOverviewData(start_datetime, end_datetime) {
  return await requestWithRetry("/fastapi/get_overview", {
    method: "POST",
    // withCredentials: true,
    params: {
      token: shopHash(),
    },
    data: {
      start_datetime: start_datetime,
      end_datetime: end_datetime
    }
  })
}

export async function getGroupedOverviewData(start_datetime, end_datetime) {
  return await requestWithRetry("/fastapi/get_grouped_overview", {
    method: "POST",
    // withCredentials: true,
    params: {
      token: shopHash(),
    },
    data: {
      start_datetime: start_datetime,
      end_datetime: end_datetime
    }
  })
}

export async function getPurchasableProducts(shopHash) {
  return await requestWithRetry(`/fastapi/get_purchasable_products`, {
    method: "GET",
    // withCredentials: true,
    params: {
      token: shopHash,
    },
  })
}

export async function getManufacturableProducts() {
  return await requestWithRetry("/fastapi/get_manufacturable_products", {
    method: "GET",
    // withCredentials: true,
    params: {
      token: shopHash(),
    },
  })
}

export async function postManufactorProducts(products) {
  return await requestWithRetry("/fastapi/manufacture_products", {
    method: "POST",
    // withCredentials: true,
    params: {
      token: shopHash(),
    },
    data: {
      products: products
    }
  })
}

export async function postConfirmManufacture(manufacture_orders, create_datetime) {
  let data = {
    manufacture_orders: manufacture_orders
  }
  if (create_datetime != undefined) {
    data['create_datetime'] = create_datetime
  }
  return await requestWithRetry("/fastapi/confirm_manufacture", {
    method: "POST",
    // withCredentials: true,
    params: {
      token: shopHash(),
    },
    data: data
  })
}

export async function getInventoryProducts() {
  return await requestWithRetry("/fastapi/get_inventory_products", {
    method: "GET",
    // withCredentials: true,
    params: {
      token: shopHash(),
    },
  })
}

export async function postTakeInventory(products) {
  return await requestWithRetry("/fastapi/take_inventory", {
    method: "POST",
    // withCredentials: true,
    params: {
      token: shopHash(),
    },
    data: {
      products: products
    }
  })
}

export async function getScrappableProducts(start_datetime, end_datetime) {
  return await requestWithRetry("/fastapi/get_scrappable_products", {
    method: "POST",
    // withCredentials: true,
    params: {
      token: shopHash(),
    },
    data: {
      start_datetime: start_datetime,
      end_datetime: end_datetime
    }
  })
}

export async function postScrapProducts(products) {
  return await requestWithRetry("/fastapi/scrap_products", {
    method: "POST",
    // withCredentials: true,
    params: {
      token: shopHash(),
    },
    data: {
      products: products
    }
  })
}

export async function postJoinLots(discardLotId, preserveLotId) {
  return await requestWithRetry("/fastapi/join_lots", {
    method: "POST",
    // withCredentials: true,
    params: {
      token: shopHash(),
    },
    data: {
      discard_lot_id: discardLotId,
      preserve_lot_id: preserveLotId,
    }
  })
}

export async function postLotProperties(lotId, props) {
  return await requestWithRetry("/fastapi/update_lot_properties", {
    method: "POST",
    // withCredentials: true,
    params: {
      token: shopHash(),
    },
    data: {
      lot_id: lotId,
      properties: JSON.stringify(props)
    }
  })
}

export async function postManufactureProperties(orderId, props) {
  return await requestWithRetry("/fastapi/update_manufacture_properties", {
    method: "POST",
    // withCredentials: true,
    params: {
      token: shopHash(),
    },
    data: {
      order_id: orderId,
      properties: JSON.stringify(props)
    }
  })
}

export async function getBoms() {
  return await requestWithRetry("/fastapi/get_boms", {
    method: "GET",
    // withCredentials: true,
    params: {
      token: shopHash(),
    }
  })
}

export async function postCancelManufactureOrder(lot_id) {
  return await requestWithRetry("/fastapi/cancel_manufacture_order", {
    method: "POST",
    // withCredentials: true,
    params: {
      token: shopHash(),
    },
    data: {
      id: lot_id
    }
  })
}

export async function postCancelScrap(lot_id) {
  return await requestWithRetry("/fastapi/cancel_scrap", {
    method: "POST",
    // withCredentials: true,
    params: {
      token: shopHash(),
    },
    data: {
      id: lot_id
    }
  })
}
